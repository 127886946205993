
import { computed, defineComponent, provide, ref } from 'vue';

interface TabStore {
  [propName: string]: boolean;
}

export default defineComponent({
  setup() {
    const titlecontainer = ref(null);
    const store = ref<TabStore>({});
    const hash = window.location.hash.substring(1);

    provide('titlecontainer', titlecontainer);
    provide('setActive', (id: string) => {
      Object.keys(store.value).map(tab => {
        store.value[tab] = false;
      });
      store.value[id] = true;
      window.location.hash = '';
    });
    provide('store', store);
    provide('initialhash', hash);

    return {
      titlecontainer,
    };
  },
});
